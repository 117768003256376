"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../css/app.css");
require("@autoprog/theme/dist/theme.css");
/**
 * Renvoie le theme actuel entre 'dark', 'light' ou null.
 * @returns le thème actuel
 */
const getTheme = () => {
    return localStorage.getItem('Acadio_Theme');
};
/**
 * Applique le theme sur la page
 */
const applyTheme = () => {
    const theme = getTheme();
    const N_Body = document.querySelector('body');
    const N_HTML = document.querySelector('html');
    if (theme) {
        N_Body.setAttribute('data-acadio-theme', theme);
        N_HTML.style.colorScheme = theme;
    }
    else {
        N_Body.removeAttribute('data-acadio-theme');
    }
};
/**
 * Initialise le message d'erreur
 */
const initErrorMessage = () => {
    const N_ErrorMessage = document.querySelector('#error-message');
    N_ErrorMessage.innerHTML = '';
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const error = urlParams.get('error');
    if (error === 'WRONG_LOGIN_OR_PASSWORD') {
        N_ErrorMessage.innerHTML = 'Identifiant ou mot de passe incorrect.';
    }
};
/**
 * Initialise le formattage du formulaire
 */
const initFormFormat = () => {
    const N_InputLogin = document.querySelector('#input-login');
    N_InputLogin.addEventListener('change', () => {
        N_InputLogin.value = N_InputLogin.value.trim();
    });
    N_InputLogin.addEventListener('input', () => {
        N_InputLogin.value = N_InputLogin.value.trim();
    });
};
/**
 * Fonction d'initialisation principale
 */
const init = () => {
    applyTheme();
    initErrorMessage();
    initFormFormat();
};
init();
